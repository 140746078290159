@import "../variables";
@import "./fonts";

:root {
    --color-black: #{#231f20};
    --color-red: #{922525};

    --font-base: "Garamond";
    --font-secondary: "Albert Sans";

    --form-label--font-family: #{"Albert Sans"};
    --text-input--font-family: #{"Albert Sans"};
    --dropdown-list--font-family: #{"Albert Sans"};
    --text-input-border-color: #{$silver};

    --button--bg-color: #{$gold};
    --button--font-color: #{$white};
    --button--font-family: #{$base-font-family};
    --button-primary--bg-color: #{$gold};
    --button-primary--border-color: #{$gold};
    --button-primary--hover-bg-color: #{$gold-hover};
    --button-primary--hover-border-color: #{$gold-hover};
    --button-primary--disabled-bg-color: #{$gold-disabled};
    --button-primary--disabled-border-color: #{$gold-disabled};
    --button-primary--disabled-bg-color: #{$white};
    --button-secondary--font-color: #{$black};
    --button-text--underline-offset: #{"6px"};

    --form-label--font-color: #{$black};
    --dropdown--placeholder-selected: #{$black};

    --utility-bar--menu-link-font: #{$secondary-font-family};
    --main-menu--clickable-font: #{$base-font-family};
    --submenu--cta-font: #{$base-font-family};
    --main-nav--account-link-text-font: #{$secondary-font-family};

    --rich-text--font-family: #{$secondary-font-family};
    --child-grid-carousel--clickable-font: #{base-font-family};
    --child-grid-carousel--clickable-transform: none;
    --value-props--text-font: #{$base-font-family};
    --tile-carousel--clickable-font: #{$base-font-family};
    --image-tiles--tile-clickable-font: #{$base-font-family};
}
