@font-face {
    font-family: "Albert Sans";
    src:
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Regular-webfont.woff2) format("woff2"),
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Regular-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Albert Sans";
    src:
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Italic-webfont.woff2) format("woff2"),
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Italic-webfont.woff) format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Albert Sans";
    src:
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Bold-webfont.woff2) format("woff2"),
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Bold-webfont.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Albert Sans";
    src:
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-BoldItalic-webfont.woff2)
            format("woff2"),
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-BoldItalic-webfont.woff)
            format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Albert Sans";
    src:
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Light-webfont.woff2) format("woff2"),
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-Light-webfont.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Albert Sans";
    src:
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-LightItalic-webfont.woff2)
            format("woff2"),
        local("Albert Sans"),
        url(../../public/fonts/AlbertSans-LightItalic-webfont.woff)
            format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Regular-webfont.woff2) format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Regular-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-ExtraBoldItalic-webfont.woff2)
            format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-ExtraBoldItalic-webfont.woff)
            format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-ExtraBold-webfont.woff2)
            format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-ExtraBold-webfont.woff) format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-BoldItalic-webfont.woff2)
            format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-BoldItalic-webfont.woff)
            format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Bold-webfont.woff2) format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Bold-webfont.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-SemiBoldItalic-webfont.woff2)
            format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-SemiBoldItalic-webfont.woff)
            format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-SemiBold-webfont.woff2)
            format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-SemiBold-webfont.woff) format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-MediumItalic-webfont.woff2)
            format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-MediumItalic-webfont.woff)
            format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Medium-webfont.woff2) format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Medium-webfont.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Garamond";
    src:
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Italic-webfont.woff2) format("woff2"),
        local("Garamond"),
        url(../../public/fonts/EBGaramond-Italic-webfont.woff) format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
